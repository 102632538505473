<script>
import api from '@/command/api'
import DetailFormGroup from '../../../components/DetailFormGroup'
// 添加用户
export default {
  name: 'assignusersDetail',
  data() {
    return {
      ...api.command.getState(),
      scienceList: [],
      tenantId: null
    }
  },
  watch: {},
  mounted() {
    this.tenantId = this.$route.query.tenantId
    this.getDataInit()
  },
  methods: {
    getDataInit() {
      api.command.getList
        .call(
          this,
          {
            url: '/system/farmTenantRole/list',
            params: {
              current: 1
            },
            paramsValue: {
              host: '/bpi'
            }
          },
          '/bpi'
        )
        .then(result => {
          this.scienceList = result.data.map(e => {
            return {
              ...e,
              name: e.roleName,
              value: e.roleCode
            }
          })
        })
    },
    getForm() {
      return {
        title: '用户',
        type: 'cardForm',
        data: [
          {
            name: '用户电话',
            type: 'input',
            key: 'phone',
            cols: 12,
            rules: [
              {
                required: true,
                type: 'string'
              }
            ]
          },
          {
            name: '用户权限',
            type: 'select',
            cols: 12,
            key: 'roleCode',
            typeData: this.scienceList
          }
        ]
      }
    },
    getFoot() {
      const left = [
        {
          name: '提交',
          type: 'primary',
          loading: this.loading,
          sumbit: true,
          onClick: data => {
            let lxfs = /^((0\d{2,3}-?\d{7,8})|(1[3465789]\d{9}))$/
            if (!lxfs.test(data.phone)) {
              this.$message.warning('请输入正确的用户电话！')
              return
            }
            let roleName = (
              this.scienceList.find(e => e.value == data.roleCode) || {
                name: ''
              }
            ).name
            api.command.createDetail.call(this, {
              url: '/system/farmTenantUser/add',
              params: {
                ...data,
                roleName,
                tenantId: this.tenantId
              },
              host: '/bpi'
            })
          }
        },
        {
          name: '取消',
          type: 'default',
          onClick: () => history.back()
        }
      ]
      return {
        left
      }
    }
  },
  render() {
    return <DetailFormGroup form={this.detail} foot={this.getFoot()} data={[this.getForm()]} />
  }
}
</script>

<style lang="less" scoped>
.entityInformation-detail {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
}
</style>
